import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import SmallImg from "../../../components/Image/SmallImg"

import {
  Title,
  Input,
  Field,
  Control,
  StaticButton,
  Columns,
  Column,
} from "../../../StyleComponents/styles"
import { FlexDiv } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Converter", link: "/units-converter/" },
  {
    name: "Latitude and Longitude To DMS",
    link: "/latitude-and-longitude-to-dns-converter/",
  },
]
const seeAlsoArray = [
  "/where-am-i-location-latitude-longitude",
  "/find-location-of-ip-address",
]

function LatitudeandLongitudeToDMS(props) {
  let [latitude, setlatitude] = useState("")
  let [latitudedms, setlatitudedms] = useState("")
  let [longitude, setlongitude] = useState("")
  let [longitudedms, setlongitudedms] = useState("")

  function decimalLatitudeToDMS(e) {
    setlatitude(e.target.value)
    let value = parseFloat(e.target.value)

    if (e.target.value[0] !== "-" && isNaN(value)) {
      setlatitude("")
      setlatitudedms("")
    } else {
      var north = "N"
      var south = "S"
      var hemisphere = value < 0 ? south : north

      var degrees = Math.floor(value)
      var minutesFromRemainder = (value - degrees) * 60
      var minutes = Math.floor(minutesFromRemainder)
      var secondsFromRemainder = (minutesFromRemainder - minutes) * 60

      var multiplier = Math.pow(10, 2)
      var seconds = Math.round(secondsFromRemainder * multiplier) / multiplier

      let result = degrees + "° " + minutes + "' " + seconds + '" ' + hemisphere

      setlatitudedms(result)
    }
  }

  function dmsToDecimal(e) {
    setlatitudedms(e.target.value)
    let value = e.target.value
    if (value === "") {
      setlatitudedms("")
      setlatitude("")
    } else {
      let dmsPattern = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′:]?\s?(?:(\d+(?:\.\d+)?)["″]?)?)?\s?([NSELWO])?/i

      var output = NaN,
        dmsMatcher,
        degrees,
        minutes,
        seconds,
        hemisphere
      dmsMatcher = dmsPattern.exec(value)
      if (dmsMatcher) {
        degrees = Number(dmsMatcher[1])

        minutes =
          typeof dmsMatcher[2] !== "undefined" ? Number(dmsMatcher[2]) / 60 : 0
        seconds =
          typeof dmsMatcher[3] !== "undefined"
            ? Number(dmsMatcher[3]) / 3600
            : 0
        hemisphere = dmsMatcher[4] || null

        if (hemisphere !== null && /[SWO]/i.test(hemisphere)) {
          degrees = Math.abs(degrees) * -1
        }

        if (degrees < 0) {
          output = degrees - minutes - seconds
        } else {
          output = degrees + minutes + seconds
        }
      }

      setlatitude(output)
    }
  }

  function longitudeC(e) {
    setlongitude(e.target.value)
    let value = parseFloat(e.target.value)
    if (e.target.value[0] !== "-" && isNaN(value)) {
      setlongitude("")
      setlongitudedms("")
    } else {
      var east = "E"
      var west = "W"
      var hemisphere = value < 0 ? west : east // west if negative

      if (value < 0) {
        value *= -1
      }
      var degrees = Math.floor(value)
      var minutesFromRemainder = (value - degrees) * 60
      var minutes = Math.floor(minutesFromRemainder)
      var secondsFromRemainder = (minutesFromRemainder - minutes) * 60
      var multiplier = Math.pow(10, 2)
      var seconds = Math.round(secondsFromRemainder * multiplier) / multiplier
      let result = degrees + "° " + minutes + "' " + seconds + '" ' + hemisphere

      setlongitudedms(result)
    }
  }

  function longitudedmsC(e) {
    setlongitudedms(e.target.value)
    let value = e.target.value
    if (value === "") {
      setlongitudedms("")
      setlongitude("")
    } else {
      var dmsPattern = /^(-?\d+(?:\.\d+)?)[°:d]?\s?(?:(\d+(?:\.\d+)?)['′:]?\s?(?:(\d+(?:\.\d+)?)["″]?)?)?\s?([NSELWO])?/i

      var output = NaN,
        dmsMatcher,
        degrees,
        minutes,
        seconds,
        hemisphere
      dmsMatcher = dmsPattern.exec(value)
      if (dmsMatcher) {
        degrees = Number(dmsMatcher[1])

        minutes =
          typeof dmsMatcher[2] !== "undefined" ? Number(dmsMatcher[2]) / 60 : 0
        seconds =
          typeof dmsMatcher[3] !== "undefined"
            ? Number(dmsMatcher[3]) / 3600
            : 0
        hemisphere = dmsMatcher[4] || null

        if (hemisphere !== null && /[SWO]/i.test(hemisphere)) {
          degrees = Math.abs(degrees) * -1
        }

        if (degrees < 0) {
          output = degrees - minutes - seconds
        } else {
          output = degrees + minutes + seconds
        }
      }

      setlongitude(output)
    }
  }

  return (
    <Layout location={props.location}>
      <SEO
        title="Latitude and Longitude To DMS Conversion"
        description="Convert latitude and longitude to DMS, DMS is the abbreviation for Degrees Minutes Seconds. Just enter the latitude and the longitude to get the DMS format."
        keywords={[
          "convert lat lng to DMS, latitude longitude converter, DMS comverter, latitude in dms, longitude in dms.",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Latitude and Longitude To DMS Converter</Title>
        <Columns>
          <Column>
            <Field>
              <Control>
                <StaticButton>Latitude in Decimal</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  value={latitude}
                  type="text"
                  placeholder="Latitude"
                  onChange={decimalLatitudeToDMS}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Longitude in Decimal</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  value={longitude}
                  type="text"
                  placeholder="Longitude"
                  onChange={longitudeC}
                />
              </Control>
            </Field>
          </Column>

          <Column>
            <Field>
              <Control>
                <StaticButton>Latitude in DMS</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  value={latitudedms}
                  type="text"
                  placeholder="DMS Latitude"
                  onChange={dmsToDecimal}
                />
              </Control>
            </Field>

            <Field>
              <Control>
                <StaticButton>Longitude in DMS</StaticButton>
              </Control>
              <Control className="expandedWidth">
                <Input
                  radius="0"
                  className="borderedRight"
                  value={longitudedms}
                  type="text"
                  placeholder="DMS Longitude"
                  onChange={longitudedmsC}
                />
              </Control>
            </Field>
          </Column>
        </Columns>
        <br />
        <Column>
          <FlexDiv maxWidth="350px" margin="auto">
            <SmallImg
              filename="latitude-logitude.png"
              alt="latitude, longitude converter dms"
            />
          </FlexDiv>
        </Column>
        <br />

        <h3> DMS</h3>
        <p>
          <strong className="mycolor">
            Degrees minutes seconds (DMS) are a special type of units used for
            measuring angles, as an alternative to decimal way to stating the
            size of an angle. <br />
            It is a recognized fact of the full circle have 360 degrees, with
            1/60th of those being 1 minute, and 1/60th of one minute being 1
            second.
            <br />
            The numerical values for latitude and longitude in decimal number
            format:
            <br />
            degrees (°), for minutes ('), for seconds ('').
            <br />
            The common abbreviations of the directions:
            <br />
            S = South
            <br />
            W = West
            <br />
            E = East
            <br />
            N = North
            <br />
          </strong>
          <br />
        </p>

        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default LatitudeandLongitudeToDMS
